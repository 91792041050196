import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Fixed } from "@components/feature-list";
import StartButton from "@components/StartButton";

const GiftcardSolutions = ({ reverse }) => {
  const images = useStaticQuery(graphql`
    query {
      xs: file(name: { eq: "gifting-solutions" }) {
        sharp: childImageSharp {
          fixed(width: 460) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      sm: file(name: { eq: "gifting-solutions" }) {
        sharp: childImageSharp {
          fixed(width: 594) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      md: file(name: { eq: "gifting-solutions" }) {
        sharp: childImageSharp {
          fixed(width: 628) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
      lg: file(name: { eq: "gifting-solutions" }) {
        sharp: childImageSharp {
          fixed(width: 728) {
            ...GatsbyImageSharpFixed_withWebp
          }
        }
      }
    }
  `);

  const sources = [
    {
      ...images.xs.sharp.fixed,
      media: `(max-width: 639px)`,
    },
    {
      ...images.sm.sharp.fixed,
      media: `(max-width: 767px)`,
    },
    {
      ...images.md.sharp.fixed,
      media: `(max-width: 1023px)`,
    },
    {
      ...images.lg.sharp.fixed,
      media: `(min-width: 1024px)`,
    },
  ];

  return (
    <Fixed description={<Description/>} image={sources} reverse={!!reverse} />
  );
}

const Description = () => (
  <>
    <div className="lg:-mt-6">
      <span className="h-12 w-12 rounded-md flex items-center justify-center bg-highlight">
        <svg className="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z" />
        </svg>
      </span>
      <div className="mt-6">
        <h2>
          <span className="block text-base text-highlight font-semibold tracking-wide uppercase">
            Platform
          </span>
          <span className="block text-3xl font-bold tracking-tight text-gray-900 mt-1">
            Gifting Solutions
          </span>
        </h2>
        <p className="mt-4 text-lg text-gray-500">
          WAIVPAY provides both digital and physical platforms to deliver gift cards dependent on the requirements of our customers.
          Our suite of products provide the following options:
        </p>
        <ul className="list-outside list-disc pl-6 mt-4 text-lg text-gray-500">
          <li>Print on demand kiosk for physical eftpos cards</li>
          <li>Digital kiosk and digital cards</li>
          <li>Concierge Service for customer service desks</li>
          <li>Webstores managing online orders</li>
          <li>Self service digital retailer brand platform.</li>
        </ul>
        <div className="mt-6">
          <StartButton to="/platform" />
        </div>
      </div>
    </div>
    {/* <div className="mt-8 border-t border-gray-200 pt-6"> */}
    {/*   <Quote /> */}
    {/* </div> */}
  </>
);

export default GiftcardSolutions;
